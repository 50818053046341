@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-black;
  font-family: 'Inter', sans-serif;
}

.App {
  background-image: url(./red.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 60%;
}